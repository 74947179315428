const menuMap = [
  {
    name: 'back',
    link: '/',
  },
];

const menuMapHome = [
  {
    name: 'about',
    label: '✍️',
    link: '/about',
  },
];


export { menuMap, menuMapHome };
